.jrJcKE {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  overflow-y: auto;
}
.modal-body {
  display: flex;
  padding: 0 40px;
}
.delete-icon {
  margin-left: 10px;
  text-align: center;
  height: 25px;
  width: 35px;
  justify-content: center;
  background-color: rgb(66, 176, 213);
  align-items: center;
  padding: 5px 5px;
  padding-top: 7px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.left-input {
  display: flex;
  flex-direction: column;
  padding-right: 5%;
  width: 30%;
  justify-content: space-between;
}

.rule-name-input {
  display: flex;
  margin-right: 5%;
  margin-bottom: 40%;
  flex-direction: column;
}

.rule-filter-input {
  display: flex;
  flex-direction: column;
}

.table-div {
  display: flex;
  width: 70%;
  flex-direction: column;
}

.table-lable {
  display: flex;
  margin-bottom: 5px;
}

.table-main {
  display: flex;
}
.button-groups {
  display: flex;
  margin-top: 20px;
}
#text-area {
  padding-right: 50%;
  max-width: 100%;
  max-height: 190px;
  min-width: 300px;
  min-height: 100px;
}
.tooltip-rule {
  max-width: 300px;
}
.eMbMrK {
  position: relative;
  z-index: 2;
}

.dWiegs {
  padding-left: 100 !important;
  position: relative;
  text-align: center !important;
}

#rule-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#rule-modal > h2 {
  text-align: center;
  background-color: #eeee;
  padding-bottom: 20px !important;
}

#react-select-5-listbox {
  z-index: auto;
}

.table-main-header-cell.ID {
  width: 50px !important;
}
.table-main.cursor-pointer {
  position: absolute !important;
  cursor: pointer !important;
  height: 20px !important;
  width: 20px !important;
  opacity: 0 !important;
}
.table-main-header-cell.customSelect {
  width: 20px !important;
}
