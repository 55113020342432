.drawer-custom {
  width: max(20vw, 300px);
  padding: 40px;
}

.shift-right {
  float: right;
}

.drawer-custom-close {
  display: flex;
  align-items: center;
  gap: 4px;
}

.name-heading{
  color: #555; 
  margin-left: 6px;
}

.drawer-title{
  margin-left: 30px;
  color: #000;
  font-weight: 600;
  margin-top: 8px;
}

.drawer-heading-title{
  color: #555;
  margin-left: 30px;
  margin-top: 20px;
}

.drawer-divider-line{
  width: 80%;
  height: 1px;
  background: #ddd;
  margin-left: 30px;
  margin-top: 40px;
}

.drawer-title-icon{
  font-weight: bold; 
  margin-left: 6px;
}

.drawer-cta-button{
  margin-top: 40px; 
  cursor: pointer;
}