.header-div {
  display: flex;
  justify-content: space-between;
  margin: 20px 5%;
}
.left-side {
  display: flex;
  justify-content: space-between;
}

/* .hseImf {
  margin: 0;
} */
#moduleText {
  width: 50%;
  margin-right: 5%;
}
.heading {
  margin: 0;
}
.create-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  max-width: 100%;
}

.kXLtyA {
  margin-top: 10px !important;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}

.gAMNej {
  margin-bottom: 0 !important;
}
.gJVsRN {
  height: fit-content !important;
}
.module-table-div {
  display: flex;
  margin: 0 5%;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.module-table {
  width: 90%;
}

.module-card {
  width: 35%;
  max-height: 50%;
  margin-right: 10%;
}
.main-card {
  margin-left: 5%;
  margin-right: 20%;
}
.name {
  cursor: pointer;
}
/* .main-card {
  margin: 10% 25%;
} */
.table-header-cell.configureDate {
  width: 80px !important;
}
.rule-search {
  margin-right: 20px;
}
.table-header-cell.assign {
  width: 80px !important;
}

.table-header-cell.rec {
  width: 110px !important;
}
.table-header-cell.customSelect {
  width: 20px !important;
}
