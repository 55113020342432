.top-input {
  display: flex;
  padding: 5% 5%;
}
.rec-name-input {
  margin-right: 5%;
}
.rec-priority-input {
  width: 100%;
  z-index: 2;
}
.bottom-input {
  padding: 2% 5%;
}
#rec-text-area {
  max-width: 100%;
  max-height: 150px;
  min-width: 100%;
  min-height: 100px;
}
#rec-modal-id > h2 {
  text-align: center;
  background-color: #eeee;
  padding-bottom: 20px !important;
}
