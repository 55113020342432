.header-div {
  display: flex;
  justify-content: space-between;
  margin: 20px 5%;
}

.heading {
  margin: 0;
}

.breadcrumb-story-rec {
  margin-left: 30px;
  width: 700px;
}
.create-button {
  justify-content: right;
  align-items: right;
}

.module-table-div {
  display: flex;
  margin: 0 5%;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-height: 430px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 0;
}
.rule-lookup {
  margin-bottom: 30px;
  max-width: 90%;
}
.rec-table {
  width: 90%;
  margin-right: 5%;
}

.rec-card {
  width: 30%;
  max-height: 440px;
  margin-right: 10%;
}
.main-card {
  margin-left: 5%;
  margin-right: 20%;
}

.kXLtyA {
  margin-top: 10px !important;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}

.gAMNej {
  margin-bottom: 0 !important;
}

.rec-table tbody tr td {
  white-space: normal !important;
}
.switch {
  margin-bottom: 25px;
  margin-right: 15%;
}
.table-header-cell.rid {
  width: 95px !important;
}
.table-header-cell.priority {
  width: 85px !important;
}
.table-header-cell.addedDate {
  width: 90px !important;
}
.table-header-cell.ractive {
  width: 50px !important;
}
.table-header-cell.desc {
  width: 270px !important;
}
.table-header-cell.assign {
  width: 70px !important;
}
.table-header-cell.ractive > div > i {
  visibility: hidden !important;
}
.table-header-cell.assign > div > i {
  visibility: hidden !important;
}
.table-header-cell.addedDate > div > i {
  visibility: hidden !important;
}
.table-header-cell.method {
  width: 30px !important;
}
