.header-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 5%;
}
.module-page-left-side {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.info-icon {
  margin-left: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
/* .hseImf {
  margin: 0;
} */
#moduleText {
  width: 50%;
  margin-right: 5%;
}
.heading {
  margin: 0;
}
.create-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  max-width: 100%;
}
.create-module-button {
  margin-left: 15px !important;
}
.delete-icon {
  margin-left: 5px;
  text-align: center;
  justify-content: center;
  background-color: rgb(66, 176, 213);
  align-items: center;
  padding: 5px 5px;
  padding-top: 7px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.edit-icon {
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  color: white;
  padding-top: 7px;
  background-color: rgb(66, 176, 213);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.div:disabled,
div[disabled] {
  background-color: #00243d80;
  cursor: not-allowed;
  color: white;
  border: none;
}

.kXLtyA {
  margin-top: 10px !important;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}
.edit-button {
  margin-left: 10px;
}
.delete-button {
  margin-left: 10px;
}
.gAMNej {
  margin-bottom: 0 !important;
}
.gJVsRN {
  height: fit-content !important;
}
.module-table-div {
  display: flex;
  margin: 0 5%;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.module-table {
  width: 90%;
}

.module-card {
  width: 35%;
  max-height: 50%;
  margin-right: 10%;
}
.main-card {
  margin-left: 5%;
  margin-right: 20%;
}

/* .main-card {
  margin: 10% 25%;
} */
.breadcrumb-story {
  margin-left: 30px;
}
.table-wrapper tbody tr td {
  white-space: normal !important;
}
.table-header-cell.rules {
  width: 85px !important;
}
.table-header-cell.customSelect {
  width: 20px !important;
}
.drawer-custom {
  width: max(20vw, 300px);
  padding: 40px;
}

.shift-right {
  float: right;
}

.drawer-custom-close {
  display: flex;
  align-items: center;
  gap: 4px;
}

a {
  text-decoration: none;
}
.clickable {
  color: rgb(0, 115, 171);
}
input {
  margin: 0 !important;
}
