.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  height: 80px;
}
.left-side {
  display: flex;
  align-items: center;
}
.logo {
  max-width: 150px;
  max-height: 30px;
}
.right-side-navbar {
  display: flex;
  gap: 30px;
}
.dashboard {
  cursor: pointer;
}
.hamburger {
  cursor: pointer;
}
.line {
  border-left: 1px solid #b2b2b2;
  height: 22px;
  margin: 0 10px;
}
.separator {
  border-top: 1px solid #b2b2b2;
}
